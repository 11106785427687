/* global window navigator */
import React from 'react';
import { useFela, } from 'react-fela';
import HtzLink from '../../HtzLink/HtzLink';
import Button from '../../Button/Button';
import useWebViewChecker from '../../../hooks/useWebViewChecker';
import { useUser, } from '../../User/UserDispenser';
import checkSiteFromConfig from '../../../utils/checkSiteFromConfig';
import { useEventTracker, } from '../../../utils/EventTracker';

const { isHDC, } = checkSiteFromConfig();

const mobileBodyWrapperStyle = ({ theme, isWebView, }) => ({
  extend: [
    theme.mq({ from: 's', }, { display: 'none', }),
    theme.mq(
      {
        until: 's',
      },
      {
        display: 'block',
        textAlign: 'center',
        paddingBottom: isWebView
          ? null
          : [
            theme.mobileStyle.menuHeight,
            `calc(${theme.mobileStyle.menuHeight} + env(safe-area-inset-bottom, 0))`,
          ],
      }
    ),
  ],
});

const mobileMainListStyle = ({ theme, }) => ({
  marginBottom: '2rem',
  maxWidth: isHDC ? '49rem' : '44rem',
  marginInlineStart: 'auto',
  marginInlineEnd: 'auto',
  textAlign: 'start',
  paddingStart: '1rem',
  columnCount: 2,
});

const linkStyle = ({ theme, }) => ({
  fontWeight: 'bold',
  extend: [ theme.type(0), ],
  display: 'inline-block',
  paddingTop: '4px',
});

const sublinkStyle = ({ theme, }) => ({
  fontWeight: '400',
  extend: [ theme.type(-1), ],
  display: 'inline-block',
  paddingBottom: '10px',
});

const liStyle = {
  marginBottom: '1rem',
};

const copyRightTextStyle = ({ theme, }) => ({
  marginBlockStart: '2rem',
  marginBottom: '4rem',
  textAlign: 'center',
  display: 'block',
  extend: [ theme.type(-3), ],
});

function MobileView() {
  const isWebView = useWebViewChecker();
  const { user, } = useUser();
  const { css, theme, } = useFela({ isWebView, });
  const { subscriptionGiftUrl, subscriptionGiftText, } = theme.mastheadSubscriptionI18n;
  const { biAction, } = useEventTracker();

  const giftPromotions = [];
  if (isHDC && user?.type === 'paying') {
    giftPromotions.push({
      name: subscriptionGiftText,
      url: subscriptionGiftUrl,
      isGift: true,
      __typename: 'NavigationLink',
      key: 'gift',
      inputTemplate: 'NavigationLink',
    });
  }
  const androidAppUrl = theme.footerMobileListsI18n.ButtonName.androidAppUrl;
  const iosAppUrl = theme.footerMobileListsI18n.ButtonName.iosAppUrl;
  const [ appUrl, setAppUrl, ] = React.useState(androidAppUrl);

  React.useEffect(() => {
    if (/(iphone|ipad)/i.test(navigator.userAgent)) {
      setAppUrl(iosAppUrl);
    }
  }, [ iosAppUrl, ]);

  return (
    <div className={css(mobileBodyWrapperStyle)}>
      <ul className={css(mobileMainListStyle)}>
        {theme.footerMobileListsI18n.MobileList.map(link => (
          <li key={link.text} className={css(liStyle)}>
            { link.type !== 'button'
              ? (
                link.sublinks ? (
                  <>
                    <div className={`${css(linkStyle)}`}>
                      {link.text}
                    </div>
                    <ul>
                      {link.sublinks.map(sublink => (
                        <li key={link.text}>
                          <HtzLink
                            key={sublink.text}
                            content={sublink.text}
                            href={sublink.link}
                            className={`${css(sublinkStyle)} ${sublink.className || ''}`}
                          />
                        </li>
                      ))}
                    </ul>
                  </>
                )
                  : (
                    <HtzLink
                      key={link.text}
                      content={link.text}
                      href={link.link}
                      className={`${css(linkStyle)} ${link.className || ''}`}
                    />
                  )
              )
              : <button type="button" key={link.text} className={`${css(linkStyle)} ${link.className || ''}`}>{link.text}</button>}
          </li>
        ))}
      </ul>
      {Array.isArray(giftPromotions) && giftPromotions.length
        ? (
          <div className={css({ marginBlockEnd: '5rem', })}>
            {giftPromotions.map(promotion => (
              <HtzLink
                className={css({
                  color: '#ffa500',
                  ':visited': {
                    color: '#ffa500',
                  },
                  ...theme.type(1),
                  fontWeight: '700',
                })}
                onClick={() => biAction({
                  actionCode: 49,
                  feature: 'Footer',
                  featureType: 'Marketing',
                  campaignName: 'HDC Gift subscription button',
                })}
                href={promotion.url}
                target="_blank"
              >
                {promotion.name}
              </HtzLink>
            ))
           }
          </div>
        ) : null}
      <div className={css({ marginBlockStart: '3rem', })}>
        <Button
          variant="inverse"
          boxModel={{ hp: 5, vp: 1, }}
          href={appUrl}
        >
          {theme.footerMobileListsI18n.ButtonName.text}
        </Button>
      </div>
      <span className={css(copyRightTextStyle)}>{theme.footerMobileListsI18n.Copyright.text}</span>
    </div>
  );
}

export default MobileView;
