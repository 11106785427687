import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { borderBottom, } from '@haaretz/htz-css-tools';
import HtzLink from '../../../HtzLink/HtzLink';
import H from '../../../AutoLevels/H';
import { ColumnTypes, PairTypes, } from './DesktopElementPropTypes';
import Section from '../../../AutoLevels/Section';

const UIPropTypes = {
  css: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([ PropTypes.arrayOf(PropTypes.node), PropTypes.node, ]).isRequired,
};

const extendedListContainerStyle = ({ theme, showMe, }) => {
  const {
    color,
    footerStyle: { borderWidth, borderStyle, },
  } = theme;
  return {
    display: showMe ? 'grid' : 'none',
    gridColumnGap: '4rem',
    gridRowGap: '2rem',
    paddingBottom: '3rem',
    marginBottom: '2rem',
    ...theme.type(-1),
    ...borderBottom(borderWidth, 0, borderStyle, color('footer', 'border')),
    ...theme.mq({ from: 's', until: 'm', }, { gridTemplateColumns: 'repeat(3, 1fr)', }),
    ...theme.mq({ from: 'm', until: 'l', }, { gridTemplateColumns: 'repeat(4, 1fr)', }),
    ...theme.mq({ from: 'l', until: 'xl', }, { gridTemplateColumns: 'repeat(5, 1fr)', }),
    ...theme.mq({ from: 'xl', }, { gridTemplateColumns: 'repeat(6, 1fr)', }),
  };
};
const ExtendedFooterContainer = ({ css, children, }) => (
  <div className={css(extendedListContainerStyle)}>{children}</div>
);
ExtendedFooterContainer.propTypes = UIPropTypes;

const listUlStyle = ({ theme, }) => ({
  ...theme.mq({ from: 'xl', }, { marginInlineEnd: '8rem', }),
});
const StyledSection = ({ css, children, }) => (
  <Section className={css(listUlStyle)}>{children}</Section>
);
StyledSection.propTypes = UIPropTypes;

const titleLiStyle = {
  marginBottom: '1rem',
  fontWeight: 'bold',
};
const StyledUlTitle = ({ css, children, }) => <H className={css(titleLiStyle)}>{children}</H>;
StyledUlTitle.propTypes = UIPropTypes;

const listLinkStyle = isBold => ({ theme, }) => ({
  marginBottom: '1rem',
  ...(isBold ? { fontWeight: 'bold', } : {}),
});

const toolboxListStyle = ({ theme, }) => ({
  gridRow: 1,
  ...theme.mq({ from: 's', until: 'm', }, { gridColumn: 3, }),
  ...theme.mq({ from: 'm', until: 'l', }, { gridColumn: 4, }),
  ...theme.mq({ from: 'l', until: 'xl', }, { gridColumn: 5, }),
  ...theme.mq({ from: 'xl', }, { gridColumn: 6, }),
});

ExpandedList.propTypes = {
  /** Footer's array of columns data */
  columnsArr: PropTypes.arrayOf(ColumnTypes).isRequired,
  /** Indicates display mode */
  showMe: PropTypes.bool.isRequired,
  /** Footer's toolbox data */
  toolbox: PairTypes.isRequired,
};

function ExpandedList({ columnsArr, toolbox, showMe, }) {
  const { css, } = useFela({ showMe, });
  const liClassName = css({ marginBottom: '1rem', });
  const listLinkRenderer = (isBold = false) => {
    const className = css(listLinkStyle(isBold));
    return link => (
      <li key={`${link.name}`} className={liClassName}>
        <HtzLink content={link.name} href={link.url} className={className} />
      </li>
    );
  };

  return (
    <ExtendedFooterContainer css={css}>
      {columnsArr.map((innerList, listIdx) => (
        // eslint-disable-next-line react/no-array-index-key
        <StyledSection key={listIdx} css={css}>
          <StyledUlTitle css={css}>{innerList.text}</StyledUlTitle>
          <ul>{innerList?.items?.map(listLinkRenderer())}</ul>
        </StyledSection>
      ))}
      {Array.isArray(toolbox) ? (
        <ul className={css(toolboxListStyle)}>{toolbox.map(listLinkRenderer(true))}</ul>
      ) : null}
    </ExtendedFooterContainer>
  );
}

export default ExpandedList;
