import React from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';
import { borderBottom, } from '@haaretz/htz-css-tools';
import gql from 'graphql-tag';
import ApolloConsumer from '../../ApolloBoundary/ApolloConsumer';
import HtzLink from '../../HtzLink/HtzLink';
import IconFaceBookLogo from '../../Icon/icons/IconFacebookLogo';
import IconTwitter from '../../Icon/icons/IconTwitter';
import IconRss from '../../Icon/icons/IconRss';
import IconMail from '../../Icon/icons/IconMail';
import IconApple from '../../Icon/icons/IconApple';
import IconAndroid from '../../Icon/icons/IconAndroid';
import VisuallyHidden from '../../VisuallyHidden/VisuallyHidden';

const GET_HOSTNAME = gql`
  query GetHostName {
    hostname @client
  }
`;

const IconMiscStyle = {
  marginTop: [ { until: 's', value: '1.5rem', }, ],
  fontSize: [ { until: 's', value: '3.5rem', }, ],
};

const desktopHeadStyle = ({
  theme: {
    color,
    mq,
    footerStyle: { borderWidth, lines, borderStyle, },
  },
}) => ({
  ...mq(
    { from: 's', },
    {
      ...borderBottom(borderWidth, lines, borderStyle, color('footer', 'border')),
      alignItems: 'baseline',
      display: 'flex',
    }
  ),
});

const LogoMiscStyle = {
  marginBottom: '-0.5rem',
  paddingTop: [
    {
      until: 's',
      value: '1.5rem',
    },
  ],
};

const LogoStyle = ({
  theme: {
    color,
    mq,
    footerStyle: {
      footerMobileBorderStyle: { borderWidth, lines, borderStyle, },
    },
  },
}) => ({
  marginBottom: '-1rem',
  ...mq(
    {
      until: 's',
    },
    {
      display: 'block',
      textAlign: 'center',
      paddingTop: '1.5rem',
      ...borderBottom(borderWidth, lines, borderStyle, color('footer', 'border')),
    }
  ),
});

const IconsUlStyle = ({ theme, }) => ({
  marginInlineStart: 'auto',
  fontSize: '4rem',
  ...theme.mq(
    {
      until: 's',
    },
    {
      display: 'block',
      textAlign: 'center',
      marginBottom: '4rem',
    }
  ),
});

const IconsListStyle = ({ isHiddenOnMobile, isLast, }) => ({ theme, }) => ({
  display: 'inline-block',
  ...(!isLast ? { marginInlineEnd: '3rem', } : {}),
  extend: [
    theme.mq(
      {
        until: 's',
      },
      {
        display: isHiddenOnMobile ? 'none' : 'inline-block',
        marginTop: '1.5rem',
        fontSize: '3rem',
        marginInlineStart: '2rem',
      }
    ),
  ],
});

const listItems = [
  { Icon: IconFaceBookLogo, text: 'Facebook', href: 'facebook', },
  { Icon: IconTwitter, text: 'Twitter', href: 'twitter', },
  {
    Icon: IconAndroid,
    text: 'Android',
    href: 'androidApp',
  },
  { Icon: IconApple, text: 'Apple', href: 'appleApp', },
  { Icon: IconMail, text: 'Email', href: 'redmail', },
  { Icon: IconRss, text: 'RSS', href: 'rss', },
];
export default function FooterHead({ logo: Logo, logoAltText, }) {
  const { css, theme, } = useFela();
  return (
    <div className={css(desktopHeadStyle)}>
      <div className={css(LogoStyle)}>
        <ApolloConsumer>
          {cache => {
            const { hostname, } = cache.readQuery({
              query: GET_HOSTNAME,
            });
            return (
              <Logo
                size={6}
                miscStyles={LogoMiscStyle}
                {...(hostname.includes('themarker.com') ? { color: 'primary', } : {})}
              />
            );
          }}
        </ApolloConsumer>
        {logoAltText && <VisuallyHidden>{logoAltText}</VisuallyHidden>}
      </div>
      <ul className={css(IconsUlStyle)}>
        {listItems.map(({ Icon, text, href, }, i) => (
          <li
            key={text}
            className={css(
              IconsListStyle({
                isHiddenOnMobile: i > 1,
                isLast: i === listItems.length - 1,
              })
            )}
          >
            <HtzLink href={theme.footerSocialLinksI18n[href]}>
              <VisuallyHidden>{text}</VisuallyHidden>
              <Icon miscStyles={i > 1 ? null : IconMiscStyle} />
            </HtzLink>
          </li>
        ))}
      </ul>
    </div>
  );
}

FooterHead.propTypes = {
  logo: PropTypes.element.isRequired,
  logoAltText: PropTypes.string,
};
FooterHead.defaultProps = {
  logoAltText: null,
};
