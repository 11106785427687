import React, { useState, useRef, useEffect, } from 'react';
import PropTypes from 'prop-types';
import { useFela, } from 'react-fela';

import ButtonFooter from '../Button/Button';
import HtzLink from '../HtzLink/HtzLink';
import EventTracker from '../../utils/EventTracker';
import LayoutFooterRow from '../PageLayout/LayoutRow';
import LayoutFooterContainer from '../PageLayout/LayoutContainer';
import ExpandedList from './elements/Desktop/ExpandedList';
// Views ///////////////////////////////////////////////////////////////////
import FooterHead from './elements/FooterHead';
import MobileView from './elements/MobileMainContainer';
import useSlotData from '../../hooks/Page/useSlotData';
import { useIsBot, } from '../../hooks/useIsBot';

const UIPropTypes = {
  css: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
};
const UIPropTypesDefaults = { className: null, };

function desktopMainListLayoutContainerStyle({ theme, }) {
  return {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: [
      { from: 's', value: theme.footerStyle.footerDesktopPaddingTop, },
    ],
    paddingBottom: [
      { from: 's', value: '8rem', },
      { until: 's', value: '4rem', },
    ],
  };
}

const headLinkStyle = ({ theme, }) => ({
  fontWeight: 'bold',
});
const StyledHeadLink = ({ content, href, css, onClick, }) => (
  <HtzLink
    content={content}
    href={href}
    onClick={onClick}
    className={css(headLinkStyle)}
  />
);
StyledHeadLink.propTypes = {
  css: PropTypes.func.isRequired,
  content: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  onClick: PropTypes.string.isRequired,
};

const ListUlStyle = () => ({
  marginInlineEnd: '2rem',
});
const StyledUlLinks = ({ css, children, }) => (
  <ul className={css(ListUlStyle)}>{children}</ul>
);
StyledUlLinks.propTypes = UIPropTypes;
StyledUlLinks.defaultProps = UIPropTypesDefaults;

const ListLiStyle = () => ({
  display: 'inline-block',
  ':after': {
    content: '"|"',
    marginInlineStart: '1rem',
    marginInlineEnd: '1rem',
    fontWeight: 700,
  },
  ':last-child:after': {
    content: '""',
  },
});
const StyledLi = ({ css, children, className, }) => {
  const classes = className
    ? `${css(ListLiStyle)} ${className}`
    : css(ListLiStyle);
  return <li className={classes}>{children}</li>;
};
StyledLi.propTypes = UIPropTypes;
StyledLi.defaultProps = UIPropTypesDefaults;

const desktopBodyStyle = ({ theme, }) => ({
  extend: [
    {
      ...theme.mq(
        {
          until: 's',
        },
        {
          display: 'none',
        }
      ),
    },
  ],
});
const StyledDesktopBody = ({ css, children, }) => (
  <div className={css(desktopBodyStyle)}>{children}</div>
);
StyledDesktopBody.propTypes = UIPropTypes;
StyledDesktopBody.defaultProps = UIPropTypesDefaults;

const headWrapperLinkStyle = ({ theme, }) => ({
  display: 'flex',
  flexWrap: 'no-wrap',
  alignItems: 'baseline',
  marginTop: '2rem',
  marginBottom: '4rem',
  extend: [ theme.mq({ until: 'l', }, { alignItems: 'start', }), ],
});
const StyledHeadLinksWrapper = ({ css, children, }) => (
  <div className={css(headWrapperLinkStyle)}>{children}</div>
);
StyledHeadLinksWrapper.propTypes = UIPropTypes;
StyledHeadLinksWrapper.defaultProps = UIPropTypesDefaults;

const optionalExtendedWrapper = ({ theme, }) => ({
  extend: [ theme.type(-2), ],
});
const StyledDesktopText = ({ css, children, }) => (
  <div
    dangerouslySetInnerHTML={{ __html: children, }}
    className={css(optionalExtendedWrapper)}
  />
);
StyledDesktopText.propTypes = UIPropTypes;
StyledDesktopText.defaultProps = UIPropTypesDefaults;

Footer.propTypes = {
  contentId: PropTypes.string.isRequired,
  logo: PropTypes.element.isRequired,
  logoAltText: PropTypes.string,
};
Footer.defaultProps = {
  logoAltText: null,
};
function Footer({ contentId, logo, logoAltText, }) {
  const { theme, css, } = useFela();
  const { isBot, } = useIsBot();
  const [ expanded, setExpanded, ] = useState(!!isBot);
  const focusElement = useRef();
  const handleClick = () => {
    setExpanded(!expanded);
  };
  useEffect(() => {
    if (expanded && !isBot) focusElement.current && focusElement.current.focus();
  }, [ expanded, isBot, ]);

  const {
    footerDesktopI18n: { ExpandedButton, },
    color,
  } = theme;
  const footer = useSlotData('footer');

  if (!footer) return null;

  const menuItems = (footer[0]
      && footer[0].navigation
      && Array.isArray(footer[0].navigation.menuItems)
      && footer[0].navigation.menuItems.slice())
    || [];
  const headList = menuItems.filter(item => !item.items?.length);
  const columns = menuItems.filter(item => (item.items?.length || 0) > 0);

  return (
    <LayoutFooterRow
      bgc={color('footer', 'bg')}
      miscStyles={{
        color: color('footer', 'text'),
        paddingInlineStart: '8rem',
        paddingInlineEnd: '8rem',
        fontFamily: theme.fontStacks[theme.footerStyle.fontStack],
      }}
      tagName="footer"
      attrs={{
        'data-google-interstitial': false,
        'data-test': 'footer',
      }}
    >
      <LayoutFooterContainer
        miscStyles={desktopMainListLayoutContainerStyle({ theme, })}
        bgc={color('footer', 'bg')}
      >
        <FooterHead logo={logo} logoAltText={logoAltText} />
        <MobileView />
        <StyledDesktopBody css={css}>
          <StyledHeadLinksWrapper css={css}>
            <EventTracker>
              {({ biAction, }) => (
                <StyledUlLinks css={css}>
                  {headList.map((item, index) => (
                    <StyledLi
                      key={item.contentId || item.value || item.url}
                      css={css}
                      className={item.className}
                    >
                      <StyledHeadLink
                        content={item.text}
                        href={item.url}
                        onClick={
                            item.url
                            && (item.url.includes('promotions-page')
                            || item.url.includes('contact-us')
                            || item.url.includes('personal-area'))
                              ? () => biAction({
                                feature: 'Footer',
                                featureType: 'Marketing',
                                actionCode: item.url.includes('promotions-page')
                                  ? 3
                                  : item.url.includes('personal-area')
                                    ? 114 : 122,
                                campaignName: item.url.includes('promotions-page')
                                  ? 'subscription'
                                  : item.url.includes('personal-area')
                                    ? 'Unsubscribe' : 'Contact Us',
                                campaignDetails: 'footer_default',
                              })
                              : () => {}
                              }
                        css={css}
                      />
                    </StyledLi>
                  ))}
                </StyledUlLinks>
              )}
            </EventTracker>
            <ButtonFooter
              variant="inverse"
              boxModel={{ hp: 4, vp: 1, }}
              onClick={handleClick}
              attrs={{
                'aria-expanded': expanded ? 'true' : 'false',
              }}
              miscStyles={{ marginInlineStart: 'auto', }}
            >
              {expanded ? ExpandedButton.close : ExpandedButton.showMore}
            </ButtonFooter>
          </StyledHeadLinksWrapper>
          <div ref={focusElement} tabIndex="-1">
            <ExpandedList
              toolbox={footer.toolbox}
              columnsArr={columns || []}
              showMe={expanded}
            />
          </div>
          <StyledDesktopText css={css}>
            {footer[0].disclaimer}
          </StyledDesktopText>
          {/* <StyledDesktopText css={css}>{Copyright.secondRow}</StyledDesktopText> */}
        </StyledDesktopBody>
      </LayoutFooterContainer>
    </LayoutFooterRow>
  );
}

export default Footer;
